* {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: rgb(39, 39, 39);
  margin: 0;
  padding: 0;
  color: aliceblue;
}



nav {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}



.main {

  margin: 3%;

}

.main h1 {
  text-align: center;
  padding: 25px ;
}


nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.nav li.active {
  background-color: green;
}

.nav li:hover {
  background-color: rgb(41, 40, 41);
}

.boxTableau {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.vignette{
  height: 300px;
  cursor: pointer;
  position: relative;
  margin:50px 10px;
}

.vignette img{
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.vignette h2,p{
  margin: 0;
}

.label{
  color: aliceblue;
}


.apropos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.content {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Creates space between the image and text box */
}

.image img{
  width: 80%;
}

.textBox {

  text-align: left;
  font-size: 20px;
margin: 20px;
  max-width: 600px; /* Sets a maximum width for the text box */
}

@media screen and (max-width: 780px) {
  /* CSS rules to apply when viewport width is 780px or less */
  /* For example: */
  .preview{
    margin: 0 !important;
  }

}